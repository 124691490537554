import { Box, type SxProps } from '@mui/material';
import { type Theme } from '@mui/material/styles';
import { SimpleTreeView, type SimpleTreeViewProps } from '@mui/x-tree-view';
import {
  type FC,
  type ReactElement,
  type RefAttributes,
  useCallback,
} from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

import { CustomTreeItem } from '@components/common/TreeView/CustomTreeItem';

export interface ITreeViewSxProps {
  item?: SxProps<Theme>;
  tree?: SxProps<Theme>;
  container?: SxProps<Theme>;
}

export interface ICustomFrontIcon {
  type: string;
  tooltip?: string;
  icon?: ReactElement;
}

export interface IRenderTreeData {
  name: string;
  enabled: boolean;
  id: number | string;
  children: IRenderTreeData[];
}

export type ITreeViewProps = {
  disabled?: boolean;
  withCopy?: boolean;
  data: IRenderTreeData[];
  sxProps?: ITreeViewSxProps;
  customFrontIcon?: ICustomFrontIcon;
} & RefAttributes<HTMLDivElement> &
  SimpleTreeViewProps<boolean | undefined>;

export const TreeViewComponent: FC<ITreeViewProps> = ({
  data,
  slots,
  disabled = false,
  withCopy = false,
  multiSelect = false,
  defaultSelectedItems,
  customFrontIcon = null,
  defaultExpandedItems = [],
  sxProps: { tree, item, container } = {},
}) => {
  const collapseIcon = slots?.collapseIcon ?? IoIosArrowDown;
  const expandIcon = slots?.expandIcon ?? IoIosArrowForward;

  const renderCustomFrontIcon = useCallback(
    (node: IRenderTreeData) => {
      switch (customFrontIcon?.type) {
        case 'indicateInactiveState':
          return !node.enabled ? customFrontIcon : null;
        default:
          return customFrontIcon;
      }
    },
    [customFrontIcon]
  );

  const renderTree = useCallback(
    (nodes: IRenderTreeData[]) =>
      nodes?.map((node) => (
        <CustomTreeItem
          customFrontIcon={customFrontIcon ? renderCustomFrontIcon(node) : null}
          disabled={disabled}
          itemId={String(node.id)}
          key={node.id}
          labelText={node.name}
          sx={item}
          withCopy={withCopy}
        >
          {Array.isArray(node.children) ? renderTree(node.children) : null}
        </CustomTreeItem>
      )),
    [customFrontIcon, disabled, item, renderCustomFrontIcon, withCopy]
  );

  return (
    <Box sx={container}>
      <SimpleTreeView
        slots={{
          ...slots,
          expandIcon,
          collapseIcon,
        }}
        aria-label="customized tree view"
        defaultExpandedItems={defaultExpandedItems}
        defaultSelectedItems={defaultSelectedItems}
        multiSelect={multiSelect}
        sx={{ overflowX: 'hidden', ...tree }}
      >
        {renderTree(data)}
      </SimpleTreeView>
    </Box>
  );
};
