import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type FC } from 'react';
import { IoFileTrayFull } from 'react-icons/io5';

interface INoData {
  emptyMessage?: string;
}

export const NoData: FC<INoData> = ({ emptyMessage = 'No data available' }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        height: '10rem',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      spacing={1}
    >
      <IoFileTrayFull
        aria-hidden
        color={theme.palette.secondary[400]}
        focusable="false"
        size={64}
      />
      <Typography align="center" color="secondary">
        {emptyMessage}
      </Typography>
    </Stack>
  );
};
