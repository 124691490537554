import { Box, type SxProps } from '@mui/material';
import { type Theme } from '@mui/material/styles';
import { type ReactNode } from 'react';

interface ITabPanelProps {
  index: number;
  value: number;
  sx?: SxProps<Theme>;
  children?: string | ReactNode;
}

export const TabPanel = (props: ITabPanelProps) => {
  const { sx, index, value, children, ...other } = props;

  return (
    <Box
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index ? <Box sx={{ p: 3, ...sx }}>{children}</Box> : null}
    </Box>
  );
};
