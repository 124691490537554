import { Accordion as MuiAccordion, type SxProps } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import isFunction from 'lodash/isFunction';
import { type FC, type JSX, useEffect, useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';

interface IAccordion {
  id?: string;
  propSX?: SxProps;
  disabled?: boolean;
  isOpened?: boolean;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  content?: JSX.Element | ((expanded: boolean) => JSX.Element);
}

export const Accordion: FC<IAccordion> = ({
  id = '',
  title,
  propSX,
  content,
  description,
  isOpened = false,
  disabled = false,
}) => {
  const [expanded, setExpanded] = useState(isOpened);

  useEffect(() => {
    setExpanded(isOpened);
  }, [isOpened]);

  return (
    <MuiAccordion
      onChange={() => {
        setExpanded(!expanded);
      }}
      disabled={disabled}
      expanded={expanded}
    >
      <AccordionSummary
        aria-controls="content"
        expandIcon={<AiOutlineRight />}
        id={id ?? String(title)}
        sx={propSX}
      >
        <Typography
          sx={{
            flexShrink: 0,
            display: 'flex',
            margin: '0 1rem',
            fontWeight: '600',
            alignItems: 'center',
          }}
        >
          {title}
        </Typography>
        <Typography component="div" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isFunction(content) ? content(expanded) : content}
      </AccordionDetails>
    </MuiAccordion>
  );
};
