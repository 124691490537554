import { type TagDescription } from '@reduxjs/toolkit/query';

import { apiSlice } from '@api/apiSlice';
import { IExportMutationParams, IExportResponse } from '@api/types';
import { API } from '@constants';
import { type ISearchParams } from '@features/filters/types';
import { type IBonusSearch } from '@modules/bonusManagement/types';
import { exportFileResponseHandler } from '@utils/exportFileResponseHandler';
import replaceURLParams from '@utils/replaceURLParams';

export const bonusesApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['bonus-list', 'bonus'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      exportData: builder.mutation<IExportResponse, IExportMutationParams>({
        query: ({ timeZone, searchParams }) => {
          const url = replaceURLParams(
            API.BONUS_MANAGEMENT.EXPORT,
            {
              timeZone,
            },
            { asQueryParam: true }
          );

          return {
            url,
            method: 'POST',
            body: searchParams,
            responseHandler: exportFileResponseHandler,
          };
        },
      }),
      getBonuses: builder.query<IBonusSearch, ISearchParams>({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.BONUS_MANAGEMENT.GRID,
        }),
        providesTags: (result) =>
          result
            ? [
                { id: 'LIST', type: 'bonus-list' },
                ...(result?.data?.map(({ id }) => ({
                  id,
                  type: 'bonus-list',
                })) as Array<TagDescription<'bonus-list'>>),
              ]
            : [{ type: 'bonus-list' }],
      }),
    }),
  });

export const { useExportDataMutation, useLazyGetBonusesQuery } = bonusesApi;
