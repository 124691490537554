import { IconButton, type IconButtonProps } from '@mui/material';
import { type FC } from 'react';

import { CalendarSvg } from '@assets';

export const CalendarButton: FC<IconButtonProps> = (props) => (
  <IconButton {...props}>
    <CalendarSvg />
  </IconButton>
);
