import { Stack, Typography, useTheme } from '@mui/material';

import { useAppSelector, useCatalog, useClock } from '@hooks';

export const Clock = () => {
  const time = useClock();
  const theme = useTheme();

  const timezone = useAppSelector((state) => state.appConfig.config.timezone);
  const { timeZoneOptions } = useCatalog();

  const timezoneLabel = timeZoneOptions.find(
    (option) => option.value === timezone
  )?.label;

  const clockColor =
    theme.palette.mode === 'dark'
      ? theme.palette.primary.main
      : theme.palette.secondary[600];

  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{
          color: clockColor,
          minWidth: '9.125rem',
        }}
        className="text-sm"
        variant="h6"
      >
        <time dateTime={time}>{time}</time>
      </Typography>
      <Typography
        sx={{
          color: clockColor,
        }}
      >
        {timezoneLabel}
      </Typography>
    </Stack>
  );
};
