import { IconButton, useTheme } from '@mui/material';
import { type FC, type MouseEvent, useCallback } from 'react';
import { MdCopyAll } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';

import { useAppSelector } from '@hooks';

interface ICopyButtonProps {
  value: string;
  fieldName: string;
}

export const CopyButton: FC<ICopyButtonProps> = ({ value, fieldName }) => {
  const [, copyTextToClipboard] = useCopyToClipboard();
  const theme = useTheme();

  const isPlatformTheme =
    useAppSelector((state) => state.appConfig.config.presetColor) ===
    'Platform';

  const handleCopy = useCallback(
    (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
      { value, fieldName }: ICopyButtonProps
    ) => {
      e.stopPropagation();
      copyTextToClipboard(value ?? '');
      toast.success(`${fieldName} has been copied`);
    },
    [copyTextToClipboard]
  );

  return (
    <IconButton
      onClick={(e) => {
        handleCopy(e, {
          value,
          fieldName,
        });
      }}
      sx={{
        padding: '0',
        width: '1.375rem',
        height: '1.375rem',
        color: isPlatformTheme
          ? theme.palette.primary.darker
          : theme.palette.grey[500],
      }}
    >
      <MdCopyAll />
    </IconButton>
  );
};
