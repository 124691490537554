import { IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type FC } from 'react';
import { BiSolidMessageRoundedError } from 'react-icons/bi';

export interface IErrorTooltipComponent {
  message: string;
}

export const ErrorTooltipComponent: FC<IErrorTooltipComponent> = ({
  message = '',
}) => {
  const theme = useTheme();

  return (
    <Tooltip
      arrow
      slotProps={{
        tooltip: {
          sx: {
            color: theme.palette.error.main,
            border: `1px solid ${theme.palette.error.main}`,
            backgroundColor: theme.palette.background.paper,
            '& .MuiTooltip-arrow::before': {
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.error.main}`,
            },
          },
        },
      }}
      title={message}
    >
      <IconButton
        disableRipple
        sx={{
          top: '0',
          bottom: '0',
          zIndex: '1',
          padding: '0',
          right: '-1rem',
          width: '0.75rem',
          height: 'inherit',
          position: 'absolute',
        }}
        color="error"
        size="small"
      >
        <BiSolidMessageRoundedError color="error" />
      </IconButton>
    </Tooltip>
  );
};
