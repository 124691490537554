import { Button, useTheme } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { type FC, useCallback } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface IBackButtonProps {
  label?: string;
  path: number | string;
  navigateOptions?: unknown;
}

export const BackButton: FC<IBackButtonProps> = ({
  path,
  label = 'Go Back',
  navigateOptions = {},
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (typeof path === 'number') {
      navigate(path);
    } else {
      if (isEmpty(navigateOptions)) {
        navigate(path);
      } else {
        navigate(path, { state: navigateOptions });
      }
    }
  }, [navigate, navigateOptions, path]);

  return (
    <Button
      sx={{
        transition: '.2s',
        minWidth: '6.875rem',
        paddingInline: '1rem',
        lineHeight: 'initial',
        color: theme.palette.text.primary,
        '&:after': {
          boxShadow: 'none',
        },
        '&:hover': {
          transition: '.2s',
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
        },
      }}
      onClick={handleBack}
      startIcon={<FaArrowLeft />}
    >
      {label}
    </Button>
  );
};
