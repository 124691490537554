import {
  Box,
  Grid2,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { ROUTES } from '@constants';
import { activeItem } from '@features/menu/menuSlice';
import { useAppDispatch } from '@hooks';
import { getBreadcrumbsTitle } from '@utils/breadcrumbs';

const HOME_PATH = 'bonus-management';

export const Breadcrumbs = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const pathnames = location.pathname
    .split('/')
    .filter((x) => x)
    .slice(0, 2);

  if (pathnames.length < 1) {
    return null;
  }

  return (
    <Box>
      <Grid2
        container
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Grid2>
          <MuiBreadcrumbs aria-label="Breadcrumb" separator="/">
            <Typography
              color="textSecondary"
              component={RouterLink}
              onClick={() => dispatch(activeItem({ openItem: [HOME_PATH] }))}
              sx={{ textDecoration: 'none' }}
              to={ROUTES.BONUS_MANAGEMENT.ROOT}
              variant="h6"
            >
              Home
            </Typography>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;
              const title = getBreadcrumbsTitle(value, pathnames);

              return last ? (
                <Typography color="textPrimary" key={to} variant="subtitle1">
                  {title}
                </Typography>
              ) : (
                <Typography
                  color="inherit"
                  component={RouterLink}
                  key={to}
                  sx={{ textDecoration: 'none' }}
                  to={to}
                  variant="subtitle1"
                >
                  {title}
                </Typography>
              );
            })}
          </MuiBreadcrumbs>
        </Grid2>
      </Grid2>
    </Box>
  );
};
