import { alpha, Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const NotFound = () => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        padding: '0.5rem',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: '-1.5rem',
        borderBottomLeftRadius: '0.25rem',
        borderBottomRightRadius: '0.25rem',
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.primary.main}`,
      }}
    >
      <Stack
        sx={{
          width: '4rem',
          height: '4rem',
          alignItems: 'center',
          borderRadius: '100%',
          justifyContent: 'center',
          background: alpha(theme.palette.primary.main, 0.2),
        }}
      >
        <Box
          sx={{
            width: '0.188rem',
            height: '0.938rem',
            background: theme.palette.primary.main,
          }}
        ></Box>
        <Box
          sx={{
            width: '0.188rem',
            height: '0.188rem',
            marginTop: '0.25rem',
            background: theme.palette.primary.main,
          }}
        ></Box>
      </Stack>
      <Typography
        sx={{
          fontSize: '1rem',
          color: 'primary.main',
          paddingLeft: '0.5rem',
        }}
      >
        Page not found
      </Typography>
    </Stack>
  );
};
